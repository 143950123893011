import { useStore } from '@nanostores/react';
import React from 'react';
import { $termsModalOpen, closeTermsModal } from '../../stores/termsModalOpenStore';
import ModalOverlay, { type ModalOverlayProps } from './ModalOverlay';
import * as styles from './TermsAndConditionsModal.module.scss';

export interface TermsAndConditionsModalProps
  extends Omit<ModalOverlayProps, 'children' | 'open' | 'onClose'> {
  className?: string;
  title: string;
  introText: string;
  children: JSX.Element;
}

type Props = TermsAndConditionsModalProps;

const TermsAndConditionsModal: React.FC<Props> = ({ title, introText, children, ...restProps }) => {
  const open = useStore($termsModalOpen);

  return (
    <ModalOverlay
      open={open}
      onClose={closeTermsModal}
      {...restProps}
      modalClassName={styles.modal}
      modalContainerClassName={styles.modalContentContainer}
      title={title}
      introText={introText}
      className={styles.modalOverlay}
    >
      <div className={styles.modalText}>{children}</div>
    </ModalOverlay>
  );
};

export default TermsAndConditionsModal;
